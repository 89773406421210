<template>
  <div class="giga-new_widget" v-if="!loading">
    <div class="articles-section">
    <div class="row">
      <template v-for="article in getArticles.content">
      <div class="col-xl-6 col-lg-12 col-md-6" :key="article.id">
        <b-link :to="{ name: 'Article', params: { id: article.id_article, article: article } }">
          <div class="article-box">
            <div class="article-thumb">
              <img
                  :src="`data:image/png;base64,${article.img}`"
                  alt="img"
              />
            </div>
            <div class="article-meta">
              <h3 class="article-title">
                {{ article.title }}
              </h3>
              <p class="article-excerpt">
                {{ article.extract }}
              </p>
              <span class="article-date">
                        {{
                  article.category +
                  ", " +
                  moment(article.date_creation).format(
                      "DD/MM/YYYY HH:mm"
                  )
                }}
              </span>
            </div>
          </div>
        </b-link>
      </div>
      </template>
    </div>
  </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "newsWidget",
  data() {
    return {
      perPage: 2,
      currentPage: 1,
      sortBy: "id",
      sortDir: true,
    }
  },
  computed: {
    ...mapGetters("regulation", ["getArticles"]),
    ...mapGetters('loader', { loading: 'getLoading' })
  },
  mounted() {
    this.getArticlesAction({
      currentPage: this.currentPage,
      perPage: this.perPage,
      sortBy: this.sortBy,
      sortDir: this.sortDir,
    });
  },
  methods: {
    ...mapActions("regulation", ["getArticlesAction"]),
  }
};
</script>