<template>
  <div>
    <div>
      <div class="row">
        <div class="col-lg-12">

          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                  class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                  style="height: 240px"
              >
                <div
                    class="title d-flex justify-content-between align-center"
                >
                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="60%"
                  ></b-skeleton>

                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="giga-widget_header">
              <h3 class="giga-widget_title">Contracts</h3>
              <b-button :to="{ name: 'Contracts' }" class="giga-widget_seeMore">Voir tout</b-button>
            </div>
          </b-skeleton-wrapper>
        </div>
        <div class="col-lg-12">
            <contracts-widget></contracts-widget>
        </div>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-lg-12 giga-margin_t30">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                  class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                  style="height: 240px"
              >
                <div
                    class="title d-flex justify-content-between align-center"
                >
                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="60%"
                  ></b-skeleton>

                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="giga-widget_header">
              <h3 class="giga-widget_title">Reporting</h3>
              <b-button :to="{ name: 'Reporting' }" class="giga-widget_seeMore">Voir tout</b-button>
            </div>
          </b-skeleton-wrapper>
        </div>
        <operation-widget></operation-widget>
      </div>
    </div>

    <div>
      <div class="row">
        <div class="col-lg-12 giga-margin_t30">
          <b-skeleton-wrapper :loading="loading">
            <template #loading>
              <div
                  class="border-raduis-md bg-white box-shadow-xl-dark p-4"
                  style="height: 240px"
              >
                <div
                    class="title d-flex justify-content-between align-center"
                >
                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="60%"
                  ></b-skeleton>

                  <b-skeleton
                      variant="light"
                      height="30px"
                      width="48px"
                  ></b-skeleton>
                </div>
                <div class="card mt-4">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                    <li class="list-group-item small">
                      <b-skeleton
                          variant="light"
                          height="15px"
                          width="100%"
                      ></b-skeleton>
                    </li>
                  </ul>
                </div>
              </div>
            </template>
            <div class="giga-widget_header">
              <h3 class="giga-widget_title">Réglementaire</h3>
              <b-button :to="{ name: 'ReglementaireOverview' }" class="giga-widget_seeMore">Voir tout</b-button>
            </div>
          </b-skeleton-wrapper>
        </div>
        <news-widget></news-widget>
      </div>
    </div>
  </div>
</template>

<script>
import contractsWidget from "./widgets/contractsWidget"
import newsWidget from "./widgets/newsWidget"
import OperationWidget from "./widgets/OperationWidget";
import {mapGetters} from "vuex";

export default {
  name: "ReportingDashboard",
  components: { contractsWidget, OperationWidget, newsWidget },
  computed: {
    ...mapGetters('loader', { loading: 'getLoading' })
  }
};
</script>