<template>
  <div>

    <dashboard-header :enableSimulation="component === 'GeneralDashboard'"></dashboard-header>

    <div v-if="component === 'GeneralDashboard'">
      <notification-bar
        ctaText="Afficher les notifications"
        state="warning"
      ></notification-bar>
    </div>

    <component v-bind:is="component"></component>

  </div>
</template>

<script>
import DashboardHeader from './DashboardHeader'
import notificationBar from "./widgets/notificationBar";
import GeneralDashboard from './GeneralDashboard';
import ReportingDashboard from './ReportingDashboard'
import {helpers} from "../../shared/helper";
import {constants as permissionsConstants} from "../../shared/constants";

export default {
  name: "Dashboard",
  components: {
    DashboardHeader,
    notificationBar,
    GeneralDashboard,
    ReportingDashboard
  },
  data() {
    return {
      allowedComponentMap: null
    }
  },
  computed: {
    component() {
      let cmp = '';
      if ((this.allowedComponentMap && this.allowedComponentMap.get(permissionsConstants.VIEW_PROJECT)
          && this.allowedComponentMap.get(permissionsConstants.VIEW_SIMULATION))) {
          cmp = 'GeneralDashboard';
      } else if ((this.allowedComponentMap && (!this.allowedComponentMap.get(permissionsConstants.VIEW_PROJECT)
          || !this.allowedComponentMap.get(permissionsConstants.VIEW_SIMULATION)))) {
          cmp = 'ReportingDashboard';
      }
      return cmp;
    }
  },
  mounted() {
    setTimeout(() => {
      this.allowedComponentMap = helpers.allowedComponent()
    }, 500);
  }
};
</script>
