<template>
  <div class="giga-notification_bar"
       :class="[(state == 'validated' ? 'notification-validated' : ''), (state == 'warning' ? 'notification-warning' : '')]"
       v-if="lastNotification"
  >
    <div class="notification-bar_content">
        <i v-if="state == 'warning'" class="urgency-icon">
            <img src="@/assets/images/alert-notif.svg" alt="">
        </i>
        <i v-if="state == 'validated'" class="validated-icon">
            <img src="@/assets/images/notification-validated.svg" alt="">
        </i>
        <p><span class="content-static" v-html="lastNotification.content"> </span></p>
    </div>
    <div class="notification-bar_button">
        <button @click="$router.push({ name: 'Notifications'})">
            <span>{{ctaText}}</span> 
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.2876 1.35535L6.71661 5.82642L1.2876 10.2975" stroke="#132D48" stroke-width="1.49036" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: "notificationBar",
  props:{
    state:{
        type: String
    },
    ctaText:{
        type: String
    }
  },
  data() {
    return {
      rolePagedFilter: {
        myPager: {
          page: 1,
          size: 1,
        },
        search: "",
      }
    }
  },
  computed: {
    ...mapGetters("notification", {
      notifications: "getDashboardImportantNotifications",
    }),
    lastNotification() {
      return this.notifications && this.notifications.length > 0 ? this.notifications[0] : null;
    },
  },
  methods: {
    ...mapActions("notification", ["getDashboardImportantNotificationsAction"]),
  },
  mounted() {
    this.getDashboardImportantNotificationsAction();
  }
};
</script>