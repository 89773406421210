<template>
  <div class="accordion" role="tablist" v-if="!loading">
    <div
        class="giga-accordion_row"
      >


      <div class="accordion-row_header" header-tag="header" role="tab">
        <b-button block v-b-toggle="'accordion-1'">
          <i class="accordion-row_icon">
            <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg" >
              <path d="M5.24537e-08 5.4C2.30796e-08 5.736 0.2475 6 0.5625 6L8.4375 6C8.7525 6 9 5.736 9 5.4C9 5.244 8.94375 5.112 8.85375 5.004C8.76375 4.896 4.91625 0.204 4.91625 0.204C4.815 0.0839997 4.66875 -3.7865e-07 4.5 -3.93402e-07C4.33125 -4.08155e-07 4.185 0.0839997 4.08375 0.204L0.14625 5.004C0.0562497 5.112 6.60916e-08 5.244 5.24537e-08 5.4Z" fill="#132D48"/>
            </svg>
          </i>

          <div class="accordion-row-cols">
            <div class="accordion-row-col accordion-col_name">
              <label>Contrat</label>
            </div>
            <div class="accordion-row-col accordion-col_date">
              Date de la signature
            </div>
            <div class="accordion-row-col accordion-col_action"></div>
          </div>
        </b-button>
      </div>
      <b-collapse
          class="accordion-tabpanel"
          :id="'accordion-1'"
          accordion="acces-row"
          role="tabpanel"
          visible
      >
        <ul class="accordion-items_list" v-if="getContracts.elements">

          <template v-for="contract in getContracts.elements">
            <li class="accordion-item accordion-multi_choices accordion-multi_choices"
                v-for="attachment in contract.attachments"
                :key="[contract.id][attachment.id]"
            >
              <div class="contract-item">
                <div class="accordion-row-col accordion-col_name">
                  <i class="file-extention">
                    <img v-if="getFileExtention(attachment.name) == 'pdf'" src="~@/assets/images/extensions/pdf.svg">
                    <img v-if="getFileExtention(attachment.name) == 'docx'" src="~@/assets/images/extensions/doc.svg">
                  </i>
                  <span>{{attachment.name}}</span>
                </div>
                <div class="accordion-row-col accordion-col_date"> {{ moment(contract.signatureDate, 'x').format('DD/MM/YYYY') }}</div>
                <div class="accordion-row-col accordion-col_action">
                  <div class="giga-button">
                    <b-button @click="download(attachment)" class="giga-blue_button">
                      <b-spinner variant="light" small class="align-middle"
                                 v-if="downloadLoader.loading === true && downloadLoader.ids.includes(attachment.id)">
                      </b-spinner>
                      <svg v-else width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="stroke"><path d="M18.2386 10.7527V14.0036C18.2386 14.8999 17.3821 15.6267 16.3258 15.6267H2.91284C1.85648 15.6267 0.999969 14.8999 0.999969 14.0036V10.7527" stroke="white" stroke-width="1.69271" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.83139 6.68774L9.61642 10.7526L14.4072 6.68774" stroke="white" stroke-width="1.69271" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.61639 10.7528V1" stroke="white" stroke-width="1.69271" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    </b-button>
                  </div>
                </div>
              </div>
            </li>
          </template>

        </ul>
      </b-collapse>
      </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {contractService} from "../../Contracts/services";

export default {
  name: "contractsWidget",
  data() {
    return {
      downloadLoader: {
        loading: false,
        ids: []
      },
    }
  },
  computed: {
    ...mapGetters('contract', ['getContracts']),

    ...mapGetters('loader', { loading: 'getLoading' })
  },
  mounted() {
    this.getContractsAction({
      currentPage: 1,
      perPage: 6,
      sortBy: 'id',
      sortDir: true
    });
  },
  methods: {
    ...mapActions('contract', ['getContractsAction']),
    getFileExtention(filename){
      return filename.split('.').pop();
    },
    download(attachment) {
      this.downloadLoader.loading = true;
      this.downloadLoader.ids.push(attachment.id);
      contractService.downloadAttachment(attachment.body, attachment.name)
          .finally(() => {
            this.downloadLoader.ids = this.downloadLoader.ids.filter(id => id !== attachment.id);
            console.log(this.downloadLoader)
          })
    }
  }
};
</script>